function Project({
    img,
    title,
    list_tool,
    list_feature,
    source,
    view,
}) {
    return <>
    <article>
        <img src={ img } alt="background"/>
        <div>
            <h3>{ title }</h3>
            <ul>
                { list_tool.map(tool => <li key={ tool.toLowerCase().trim().replaceAll(` `, `-`) }>{ tool }</li>) }
            </ul>
            <ul>
                { list_feature.map(feature => <li key={ feature }>{ feature }</li>) }
            </ul>
            <nav>
                { source && <a href={ source }><span><i className="fab fa-git"/></span></a> }
                { view && <a href={ view }><span><i className="fas fa-eye"/></span></a> }
            </nav>
        </div>
    </article>
    </>
}
const project = {}
project.commerce = <>
    <Project
        img="/file/image/abstract/min/abstract4.jpg"
        title="Commerce"
        list_tool={ [
            `SQL`,
            `Java`,
            `Spring`,
            `Rest`,
            `React`,
            `SaSS`,
        ] }
        list_feature={ [
            `account system`,
            `catalogue system`,
            `search system`,
            `score system`,
            `review system`,
            `payment system`,
        ] }
        view="https://zarimirmitev.com/commerce/"
    />
</>

project.filesystem = <>
    <Project
        img="/file/image/abstract/min/abstract5.jpg"
        title="Filesystem"
        list_tool={ [
            `SQL`,
            `Rust`,
        ] }
        list_feature={ [
            `snapshot system`,
            `backup system`,
        ] }
        // view="https://zarimirmitev.com/commerce/"
    />
</>

project.limpio = <>
    <Project
        img="/file/image/abstract/min/abstract2.jpg"
        title="Limpio"
        list_tool={ [
            `Node`,
            `Express`,
            `React`,
            `SaSS`,
        ] }
        list_feature={ [
            `translation system`,
            `contact system`,
        ] }
        view="https:/limpio.com"
    />
</>

export default function Porfolio() {
    return (
        <section id="portfolio">
            <header>
                <h2>PORTFOLIO</h2>
            </header>
            <div>
                { project.commerce }
                {/* { project.filesystem } */}
                {/* { project.limpio } */}
            </div>
        </section>
    )
}