import { HashLink } from 'react-router-hash-link'


function Skillset({
    icon,
    title,
    list_skill,
}) {
    return <>
    <article>
        <p><i className={ icon }/></p>
        <h3>{ title }</h3>
        <ul>
            { list_skill.map(skill => <li key={ skill.toLowerCase().trim().replaceAll(` `, `-`) }>{ skill }</li>) }
        </ul>
    </article>
    </>
}


const skillsets = {}
skillsets.teaching = <>
    <Skillset
        icon="fas fa-graduation-cap"
        title="Teaching"
        list_skill={ [
            `Probability Theory`,
            `Graph Theory`,
            `Theory of Computation`,
            `Linear Algebra`,
            `Abstract Algebra`,
        ] }
    />
</>

skillsets.backend = <>
    <Skillset
        icon="fas fa-tools"
        title="Backend"
        list_skill={ [
            `Java Spring`,
            `C# ASP.NET`,
            `Python Django`,
            `Node Express`,
            `SQL`,
        ] }
    />
</>

skillsets.frontend = <>
    <Skillset
        icon="fas fa-pencil-ruler"
        title="Frontend"
        list_skill={ [
            `React`,
            `Angular`,
            `Vue`,
            `Bootstrap`,
            `Tailwind`,
        ] }
    />
</>


skillsets.programming = <>
    <Skillset
        icon="fas fa-tools"
        title="Programming"
        list_skill={ [
            `SQL`,
            `Java`,
            `Spring`,
            `React`,
            `Rust`,
        ] }
    />
</>


const About = _ => {
    return (
        <section id="about">
            <header>
                <h2>About</h2>
                <p>I'm a full stack web developer with a CS degree and five years of experience teaching computer science and mathematics to university students. Studying at an international university taught me to be organised, adaptable and to thrive in a dynamic environment.</p>
                <nav>
                    <HashLink to="/page/home#portfolio" smooth>Portfolio</HashLink>
                    <a href="/file/pdf/resume.pdf">Resume</a>
                    <HashLink to="/page/home#contact" smooth>Contact</HashLink>
                </nav>
            </header>
            <div>
                { skillsets.teaching }
                { skillsets.programming }
            </div>
        </section>
    )
}


export default About
