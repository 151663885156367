const Footer = _ => {
    return (
        <footer id="footer">
            {/* <nav>
                <div>
                    <a className="fab fa-linkedin" href="https://linkedin.com/in/ZarimirMitev"/>
                </div>
                <div>
                    <a className="fab fa-git" href="https://gitlab.com/ZarimirMitev"/>
                </div>
            </nav> */}
            <p><span>&copy; ZARIMIR</span><span>MITEV <time dateTime="2022">2022</time></span></p>
        </footer>
    )
}


export default Footer
